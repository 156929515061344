/**
 * Generated by orval v7.7.0 🍺
 * Do not edit manually.
 * Brands Web API v2
 * OpenAPI spec version: v2
 */
import * as axios from 'axios';
import type {
  AxiosRequestConfig,
  AxiosResponse
} from 'axios';

import type {
  AddCustomReportSchema,
  AddEmployeeAccountSchema,
  AddEmployeeIdentificationInfoSchema,
  ChangePasswordRequestSchema,
  CheckSchema,
  CreateChecksPayloadSchema,
  CustomReportDetailsSchema,
  CustomReportsListItemSchema,
  DivisionSchema,
  EmployeeDetailsSchema,
  EmployeeIdentificationInfoSchema,
  EmployeeJobDropdownsSchema,
  EmployeeListFilterSchema,
  EmployeeListSchema,
  EmployeeTimeAndLaborSchema,
  ForgotPasswordRequestSchema,
  ImpersonateUserParams,
  LoginRequestSchema,
  LogoutRequestSchema,
  ManualCheckDetailsSchema,
  OnboardingEmployeeSchema,
  PayCodeSchema,
  PayrollChecksColumnSettingsSchema,
  PayrollChecksSortSettingsSchema,
  PayrollEntryIssueSchema,
  PayrollSchema,
  PayrollSettingsSchema,
  PayrollStatusDataSchema,
  ResendInviteEmailRequestSchema,
  ResetPasswordRequestSchema,
  ReviewEmployeeIdentificationInfoSchema,
  RunCustomReportSchema,
  SetUpPasswordSchema,
  SetUpTwoFactorAuthSchema,
  ShareCustomReportSchema,
  SignEmployeeIdentificationFormResultSchema,
  SignIdentificationFormBody,
  SwitchProfileRequestSchema,
  TwoFactorAuthDetailsSchema,
  TwoFactorLoginRequestSchema,
  UpdateCustomReportSchema,
  UpdateEmployeeIdDocumentsInfoResponseSchema,
  UpdateEmployeeIdDocumentsInfoSchema,
  UpdateEmployeeIdentificationDocumentsBody,
  UpdateEmployeeInfoSchema,
  UpdateProfileRequestSchema,
  UserAuthorizationResultsSchema,
  VerifyCodeSchema
} from './schemas';

import { createFormData } from '../overrides/create-form-data';
export const changePassword = <TData = AxiosResponse<UserAuthorizationResultsSchema>>(
    changePasswordRequestSchema: ChangePasswordRequestSchema, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.default.post(
      `/api/auth/ChangePassword`,
      changePasswordRequestSchema,options
    );
  }

export const setUpPassword = <TData = AxiosResponse<UserAuthorizationResultsSchema>>(
    setUpPasswordSchema: SetUpPasswordSchema, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.default.post(
      `/api/auth/SetUpPassword`,
      setUpPasswordSchema,options
    );
  }

export const forgotPassword = <TData = AxiosResponse<UserAuthorizationResultsSchema>>(
    forgotPasswordRequestSchema: ForgotPasswordRequestSchema, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.default.post(
      `/api/auth/ForgotPassword`,
      forgotPasswordRequestSchema,options
    );
  }

export const impersonateUser = <TData = AxiosResponse<UserAuthorizationResultsSchema>>(
    userId: number,
    params?: ImpersonateUserParams, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.default.post(
      `/api/auth/ImpersonateUser/${userId}`,undefined,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

export const login = <TData = AxiosResponse<UserAuthorizationResultsSchema>>(
    loginRequestSchema: LoginRequestSchema, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.default.post(
      `/api/auth/login`,
      loginRequestSchema,options
    );
  }

export const logoutImpersonate = <TData = AxiosResponse<UserAuthorizationResultsSchema>>(
    logoutRequestSchema: LogoutRequestSchema, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.default.post(
      `/api/auth/Logout`,
      logoutRequestSchema,options
    );
  }

export const getProfile = <TData = AxiosResponse<UserAuthorizationResultsSchema>>(
     options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.default.get(
      `/api/auth/Profile`,options
    );
  }

export const resendInviteEmail = <TData = AxiosResponse<UserAuthorizationResultsSchema>>(
    resendInviteEmailRequestSchema: ResendInviteEmailRequestSchema, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.default.post(
      `/api/auth/ResendInviteEmail`,
      resendInviteEmailRequestSchema,options
    );
  }

export const resetPassword = <TData = AxiosResponse<UserAuthorizationResultsSchema>>(
    resetPasswordRequestSchema: ResetPasswordRequestSchema, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.default.post(
      `/api/auth/ResetPassword`,
      resetPasswordRequestSchema,options
    );
  }

export const switchProfile = <TData = AxiosResponse<UserAuthorizationResultsSchema>>(
    switchProfileRequestSchema: SwitchProfileRequestSchema, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.default.post(
      `/api/auth/SwitchProfile`,
      switchProfileRequestSchema,options
    );
  }

export const twoFactorLoginWithOTP = <TData = AxiosResponse<UserAuthorizationResultsSchema>>(
    twoFactorLoginRequestSchema: TwoFactorLoginRequestSchema, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.default.post(
      `/api/auth/TwoFactorLogin`,
      twoFactorLoginRequestSchema,options
    );
  }

export const updateUserProfile = <TData = AxiosResponse<UserAuthorizationResultsSchema>>(
    updateProfileRequestSchema: UpdateProfileRequestSchema, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.default.post(
      `/api/auth/UpdateUserProfile`,
      updateProfileRequestSchema,options
    );
  }

/**
 * @summary Gets report layouts
 */
export const getCustomReports = <TData = AxiosResponse<CustomReportsListItemSchema[]>>(
     options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.default.get(
      `/api/CustomReports`,options
    );
  }

/**
 * @summary Adds report layout
 */
export const addCustomReport = <TData = AxiosResponse<void>>(
    addCustomReportSchema: AddCustomReportSchema, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.default.post(
      `/api/CustomReports`,
      addCustomReportSchema,options
    );
  }

/**
 * @summary Gets report layout by id
 */
export const getCustomReport = <TData = AxiosResponse<CustomReportDetailsSchema>>(
    id: number, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.default.get(
      `/api/CustomReports/${id}`,options
    );
  }

/**
 * @summary Runs report layout by id
 */
export const runCustomReport = <TData = AxiosResponse<unknown[]>>(
    id: number,
    runCustomReportSchema: RunCustomReportSchema, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.default.post(
      `/api/CustomReports/${id}`,
      runCustomReportSchema,options
    );
  }

/**
 * @summary Updates report layout by id
 */
export const updateCustomReport = <TData = AxiosResponse<void>>(
    id: number,
    updateCustomReportSchema: UpdateCustomReportSchema, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.default.put(
      `/api/CustomReports/${id}`,
      updateCustomReportSchema,options
    );
  }

/**
 * @summary Deletes report layout by id
 */
export const deleteCustomReport = <TData = AxiosResponse<void>>(
    id: number, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.default.delete(
      `/api/CustomReports/${id}`,options
    );
  }

/**
 * @summary Gets pay codes
 */
export const getPayCodes = <TData = AxiosResponse<PayCodeSchema[]>>(
     options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.default.get(
      `/api/CustomReports/PayCodes`,options
    );
  }

/**
 * @summary Share report layout
 */
export const shareCustomReport = <TData = AxiosResponse<void>>(
    id: number,
    shareCustomReportSchema: ShareCustomReportSchema, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.default.post(
      `/api/CustomReports/Shared/${id}`,
      shareCustomReportSchema,options
    );
  }

/**
 * @summary Adds new user account for employee
 */
export const addNewEmployeeAccount = <TData = AxiosResponse<UserAuthorizationResultsSchema>>(
    addEmployeeAccountSchema: AddEmployeeAccountSchema, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.default.post(
      `/api/EmployeeOnboarding/Accounts`,
      addEmployeeAccountSchema,options
    );
  }

/**
 * @summary Gets employee identification info
 */
export const getEmployeeIdentificationInfo = <TData = AxiosResponse<EmployeeIdentificationInfoSchema>>(
     options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.default.get(
      `/api/EmployeeOnboarding/IdentificationInfo`,options
    );
  }

/**
 * @summary Adds employee identification info
 */
export const addEmployeeIdentificationInfo = <TData = AxiosResponse<void>>(
    addEmployeeIdentificationInfoSchema: AddEmployeeIdentificationInfoSchema, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.default.post(
      `/api/EmployeeOnboarding/IdentificationInfo`,
      addEmployeeIdentificationInfoSchema,options
    );
  }

/**
 * @summary Updates employee identification documents
 */
export const updateEmployeeIdentificationDocuments = <TData = AxiosResponse<void>>(
    updateEmployeeIdentificationDocumentsBody: UpdateEmployeeIdentificationDocumentsBody, options?: AxiosRequestConfig
 ): Promise<TData> => {const formData = createFormData(updateEmployeeIdentificationDocumentsBody)
    return axios.default.put(
      `/api/EmployeeOnboarding/IdentificationInfo/Documents`,
      formData,options
    );
  }

/**
 * @summary Gets onboarding employees
 */
export const getOnboardingEmployees = <TData = AxiosResponse<OnboardingEmployeeSchema[]>>(
     options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.default.get(
      `/api/EmployeeOnboardingManager/Employees`,options
    );
  }

/**
 * @summary Gets onboarding employee details by onboarding employee number
 */
export const getOnboardingEmployee = <TData = AxiosResponse<EmployeeDetailsSchema>>(
    empNum: number, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.default.get(
      `/api/EmployeeOnboardingManager/Employees/${empNum}`,options
    );
  }

/**
 * @summary Approves employee onboarding
 */
export const approveEmployeeOnboarding = <TData = AxiosResponse<void>>(
    empNum: number,
    updateEmployeeInfoSchema: UpdateEmployeeInfoSchema, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.default.post(
      `/api/EmployeeOnboardingManager/Employees/${empNum}`,
      updateEmployeeInfoSchema,options
    );
  }

/**
 * @summary Gets I-9 form for employee
 */
export const getIdentificationForm = <TData = AxiosResponse<Blob>>(
    empNum: number, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.default.get(
      `/api/EmployeeOnboardingManager/Employees/${empNum}/IdentificationForm`,{
        responseType: 'blob',
    ...options,}
    );
  }

/**
 * @summary Signs I-9 form as employer
 */
export const signIdentificationForm = <TData = AxiosResponse<SignEmployeeIdentificationFormResultSchema>>(
    empNum: number,
    signIdentificationFormBody: SignIdentificationFormBody, options?: AxiosRequestConfig
 ): Promise<TData> => {const formData = createFormData(signIdentificationFormBody)
    return axios.default.post(
      `/api/EmployeeOnboardingManager/Employees/${empNum}/IdentificationForm`,
      formData,options
    );
  }

/**
 * @summary Gets onboarding employee identification info
 */
export const getOnboardingEmployeeIdentificationInfo = <TData = AxiosResponse<ReviewEmployeeIdentificationInfoSchema>>(
    empNum: number, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.default.get(
      `/api/EmployeeOnboardingManager/Employees/${empNum}/IdentificationInfo`,options
    );
  }

/**
 * @summary Updates onboarding employee identification info
 */
export const updateEmployeeIdentificationInfo = <TData = AxiosResponse<UpdateEmployeeIdDocumentsInfoResponseSchema>>(
    empNum: number,
    updateEmployeeIdDocumentsInfoSchema: UpdateEmployeeIdDocumentsInfoSchema, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.default.put(
      `/api/EmployeeOnboardingManager/Employees/${empNum}/IdentificationInfo`,
      updateEmployeeIdDocumentsInfoSchema,options
    );
  }

/**
 * @summary Gets W-4 form for employee by type (Federal or State)
 */
export const getTaxForm = <TData = AxiosResponse<Blob>>(
    empNum: number,
    type: string, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.default.get(
      `/api/EmployeeOnboardingManager/Employees/${empNum}/W4Forms/${type}`,{
        responseType: 'blob',
    ...options,}
    );
  }

export const getEmployees = <TData = AxiosResponse<EmployeeListSchema>>(
    employeeListFilterSchema: EmployeeListFilterSchema, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.default.post(
      `/api/employees`,
      employeeListFilterSchema,options
    );
  }

/**
 * @summary Activates direct deposit accounts (skips pre-note)
 */
export const skipPreNote = <TData = AxiosResponse<void>>(
    empnum: number, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.default.post(
      `/api/Employees/${empnum}/DirectDeposit/Activation`,undefined,options
    );
  }

export const updateEmployeeTimeAndLabor = <TData = AxiosResponse<EmployeeTimeAndLaborSchema>>(
    empnum: number,
    employeeTimeAndLaborSchema: EmployeeTimeAndLaborSchema, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.default.post(
      `/api/Employees/${empnum}/Payroll/TimeAndLabor`,
      employeeTimeAndLaborSchema,options
    );
  }

export const getDivisions = <TData = AxiosResponse<DivisionSchema[]>>(
     options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.default.get(
      `/api/employees/Divisions`,options
    );
  }

export const getEmployeeJobDropdowns = <TData = AxiosResponse<EmployeeJobDropdownsSchema>>(
     options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.default.get(
      `/api/employees/EmployeeJob/Options`,options
    );
  }

/**
 * @summary Gets manual check details by key
 */
export const getManualCheckDetails = <TData = AxiosResponse<ManualCheckDetailsSchema>>(
    checkKey: string, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.default.get(
      `/api/ManualChecks/${checkKey}`,options
    );
  }

/**
 * @summary Gets payroll entry issues
 */
export const getPayrollEntryIssues = <TData = AxiosResponse<PayrollEntryIssueSchema[]>>(
    payrollNumber: number, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.default.get(
      `/api/PayrollAssistant/${payrollNumber}/PayrollEntryIssues`,options
    );
  }

/**
 * @summary Gets payroll
 */
export const getPayroll = <TData = AxiosResponse<PayrollSchema>>(
    payrollNumber: number, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.default.get(
      `/api/Payrolls/${payrollNumber}`,options
    );
  }

/**
 * @summary Adds check to payroll
 */
export const createChecks = <TData = AxiosResponse<void>>(
    payrollNumber: number,
    createChecksPayloadSchema: CreateChecksPayloadSchema, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.default.post(
      `/api/Payrolls/${payrollNumber}/Checks`,
      createChecksPayloadSchema,options
    );
  }

/**
 * @summary Removes check from payroll
 */
export const removeCheck = <TData = AxiosResponse<CheckSchema[]>>(
    payrollNumber: number,
    checkKey: string, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.default.delete(
      `/api/Payrolls/${payrollNumber}/Checks/${checkKey}`,options
    );
  }

/**
 * @summary Updates checks column settings
 */
export const updateChecksColumnSettings = <TData = AxiosResponse<PayrollChecksColumnSettingsSchema>>(
    payrollNumber: string,
    payrollChecksColumnSettingsSchema: PayrollChecksColumnSettingsSchema, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.default.post(
      `/api/Payrolls/${payrollNumber}/Checks/ColumnSettings`,
      payrollChecksColumnSettingsSchema,options
    );
  }

/**
 * @summary Updates checks sort settings
 */
export const updateChecksSortSettings = <TData = AxiosResponse<PayrollChecksSortSettingsSchema>>(
    payrollNumber: string,
    payrollChecksSortSettingsSchema: PayrollChecksSortSettingsSchema, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.default.post(
      `/api/Payrolls/${payrollNumber}/Checks/SortSettings`,
      payrollChecksSortSettingsSchema,options
    );
  }

/**
 * @summary Updates type for checks in current payroll to direct deposit and activates direct deposit accounts for employee
 */
export const updateChecksTypeToDirectDeposit = <TData = AxiosResponse<CheckSchema>>(
    payrollNumber: number,
    employeeNumber: number, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.default.post(
      `/api/Payrolls/${payrollNumber}/Employees/${employeeNumber}/DirectDeposit`,undefined,options
    );
  }

/**
 * @summary Update payroll settings
 */
export const updatePayrollSettings = <TData = AxiosResponse<PayrollSettingsSchema>>(
    payrollSettingsSchema: PayrollSettingsSchema, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.default.post(
      `/api/Payrolls/PayrollSettings`,
      payrollSettingsSchema,options
    );
  }

/**
 * @summary Gets company payroll status
 */
export const getCompanyPayrollStatus = <TData = AxiosResponse<PayrollStatusDataSchema>>(
     options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.default.get(
      `/api/Payrolls/Status`,options
    );
  }

export const getTwoFactorAuthDetails = <TData = AxiosResponse<TwoFactorAuthDetailsSchema>>(
     options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.default.get(
      `/api/TwoFactorAuth`,options
    );
  }

export const sendTwoFactorAuthCode = <TData = AxiosResponse<void>>(
    setUpTwoFactorAuthSchema: SetUpTwoFactorAuthSchema, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.default.post(
      `/api/TwoFactorAuth/SendCode`,
      setUpTwoFactorAuthSchema,options
    );
  }

export const verifyTwoFactorAuthCode = <TData = AxiosResponse<UserAuthorizationResultsSchema>>(
    verifyCodeSchema: VerifyCodeSchema, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.default.post(
      `/api/TwoFactorAuth/VerifyCode`,
      verifyCodeSchema,options
    );
  }

export type ChangePasswordResult = AxiosResponse<UserAuthorizationResultsSchema>
export type SetUpPasswordResult = AxiosResponse<UserAuthorizationResultsSchema>
export type ForgotPasswordResult = AxiosResponse<UserAuthorizationResultsSchema>
export type ImpersonateUserResult = AxiosResponse<UserAuthorizationResultsSchema>
export type LoginResult = AxiosResponse<UserAuthorizationResultsSchema>
export type LogoutImpersonateResult = AxiosResponse<UserAuthorizationResultsSchema>
export type GetProfileResult = AxiosResponse<UserAuthorizationResultsSchema>
export type ResendInviteEmailResult = AxiosResponse<UserAuthorizationResultsSchema>
export type ResetPasswordResult = AxiosResponse<UserAuthorizationResultsSchema>
export type SwitchProfileResult = AxiosResponse<UserAuthorizationResultsSchema>
export type TwoFactorLoginWithOTPResult = AxiosResponse<UserAuthorizationResultsSchema>
export type UpdateUserProfileResult = AxiosResponse<UserAuthorizationResultsSchema>
export type GetCustomReportsResult = AxiosResponse<CustomReportsListItemSchema[]>
export type AddCustomReportResult = AxiosResponse<void>
export type GetCustomReportResult = AxiosResponse<CustomReportDetailsSchema>
export type RunCustomReportResult = AxiosResponse<unknown[]>
export type UpdateCustomReportResult = AxiosResponse<void>
export type DeleteCustomReportResult = AxiosResponse<void>
export type GetPayCodesResult = AxiosResponse<PayCodeSchema[]>
export type ShareCustomReportResult = AxiosResponse<void>
export type AddNewEmployeeAccountResult = AxiosResponse<UserAuthorizationResultsSchema>
export type GetEmployeeIdentificationInfoResult = AxiosResponse<EmployeeIdentificationInfoSchema>
export type AddEmployeeIdentificationInfoResult = AxiosResponse<void>
export type UpdateEmployeeIdentificationDocumentsResult = AxiosResponse<void>
export type GetOnboardingEmployeesResult = AxiosResponse<OnboardingEmployeeSchema[]>
export type GetOnboardingEmployeeResult = AxiosResponse<EmployeeDetailsSchema>
export type ApproveEmployeeOnboardingResult = AxiosResponse<void>
export type GetIdentificationFormResult = AxiosResponse<Blob>
export type SignIdentificationFormResult = AxiosResponse<SignEmployeeIdentificationFormResultSchema>
export type GetOnboardingEmployeeIdentificationInfoResult = AxiosResponse<ReviewEmployeeIdentificationInfoSchema>
export type UpdateEmployeeIdentificationInfoResult = AxiosResponse<UpdateEmployeeIdDocumentsInfoResponseSchema>
export type GetTaxFormResult = AxiosResponse<Blob>
export type GetEmployeesResult = AxiosResponse<EmployeeListSchema>
export type SkipPreNoteResult = AxiosResponse<void>
export type UpdateEmployeeTimeAndLaborResult = AxiosResponse<EmployeeTimeAndLaborSchema>
export type GetDivisionsResult = AxiosResponse<DivisionSchema[]>
export type GetEmployeeJobDropdownsResult = AxiosResponse<EmployeeJobDropdownsSchema>
export type GetManualCheckDetailsResult = AxiosResponse<ManualCheckDetailsSchema>
export type GetPayrollEntryIssuesResult = AxiosResponse<PayrollEntryIssueSchema[]>
export type GetPayrollResult = AxiosResponse<PayrollSchema>
export type CreateChecksResult = AxiosResponse<void>
export type RemoveCheckResult = AxiosResponse<CheckSchema[]>
export type UpdateChecksColumnSettingsResult = AxiosResponse<PayrollChecksColumnSettingsSchema>
export type UpdateChecksSortSettingsResult = AxiosResponse<PayrollChecksSortSettingsSchema>
export type UpdateChecksTypeToDirectDepositResult = AxiosResponse<CheckSchema>
export type UpdatePayrollSettingsResult = AxiosResponse<PayrollSettingsSchema>
export type GetCompanyPayrollStatusResult = AxiosResponse<PayrollStatusDataSchema>
export type GetTwoFactorAuthDetailsResult = AxiosResponse<TwoFactorAuthDetailsSchema>
export type SendTwoFactorAuthCodeResult = AxiosResponse<void>
export type VerifyTwoFactorAuthCodeResult = AxiosResponse<UserAuthorizationResultsSchema>
